.projectContainer {
    border: 1px solid #083e00;
    background-color: #0000006e;
    border-radius: 2em;
    margin: 5px;
    max-height: 463px;
    padding-left: 15px;
    padding-right: 15px;
    width: 300px;
}

.projectContainer:hover {
    cursor: pointer;
}

.projectContainer img {
    margin: 0 auto;
    display: block;
    border-radius: 2em;
    width: 70%;
}

.projectContainer h1 {
    display: inline;
    text-align: center;
}

.click {
    margin: 40px auto;
    padding: 5px;
    width: 50%;
    border: 1px solid white;
    border-radius: 1em;
}