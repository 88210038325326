.body {
    display: flex;
    background: rgb(36, 46, 130);
    background: -moz-radial-gradient(
      ellipse at bottom,
      rgba(36, 46, 130, 1) 0%,
      rgba(2, 0, 36, 1) 100%
    );
    background: -webkit-radial-gradient(
      ellipse at bottom,
      rgba(36, 46, 130, 1) 0%,
      rgba(2, 0, 36, 1) 100%
    );
    background: radial-gradient(
      ellipse at bottom,
      rgba(36, 46, 130, 1) 0%,
      rgba(2, 0, 36, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#242e82",endColorstr="#020024",GradientType=1);
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: "Quicksand", sans-serif, Arial, Helvetica, sans-serif;
    padding-bottom: 10px;
  }