.navlinks {
  display: flex;
  flex-direction: column;
  height: 0px;
  overflow-y: hidden;
  transition: height 350ms ease-out;
}

.isVisible {
    height: 240px;
    max-height: 75vh;
    overflow-y: scroll;
}

.navlinks a {
  font-family: 'Barlow', sans-serif, Arial, Helvetica, sans-serif;
  width: 50%;
  color: #35382e;
  margin: 5px auto 5px auto;
  background-color: aquamarine;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 2px 3px white;
  text-align: center;
  text-decoration: none;
}

@media only screen and (min-width: 800px) {
  .navlinks {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    height: auto;
    overflow: hidden;
  }

  .navlinks a {
    width: 200px;
    margin: 10px;
    text-align: center;
    min-width: 0;
    transition: 300ms;
  }

  .navlinks a:hover {
    background-color: white;
  }
}