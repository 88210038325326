.backdrop {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
  width: clamp(30%, 300px, 90%);
  height: clamp(80%, 410px, 90%);
  padding-bottom: 5px;
  background-color: white;
  border-radius: 1em;
  overflow: hidden;
  color: black;
  z-index: 11;
  overflow-y: auto;
}

.content {
  color: black;
}

.modal img {
  width: 100% !important;
  height: auto !important;
}

.modal p {
  font-size: 20px;
}

.button {
  display: block;
  width: 50%;
  max-width: 300px;
  padding: 5px;
  color: #35382e;
  margin: auto;
  background-color: aquamarine;
  border-radius: 10px;
  margin-top: 20px;
}