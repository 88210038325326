header {
    width: 100%;
    background-color: #2a282b;
    padding: 10px;
    color: white;
    font-family: 'Quicksand', sans-serif, Arial, Helvetica, sans-serif;
    position: fixed;
    border-bottom: 1px solid black;
    z-index: 2;
}

header h3 {
    width: 50%;
    align-self: center;
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
}

.font {
    text-align: right;
}

.modal {
    background-color: black;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: transparent;
}

@media only screen and (min-width: 800px) {
    .modal {
        display: none;
    }

    header {
        position: relative;
    }

    .font {
        display: none;
    }
}