.icon {
    display: inline-block;
    text-decoration: none;
    color: white;
    margin: 20px;
    transition: 500ms;
}

.icon span {
    display: block;
}

.icon:hover {
    color: #a8e636;
}