.body {
  background: rgb(36, 46, 130);
  background: -moz-radial-gradient(
    ellipse at bottom,
    rgba(36, 46, 130, 1) 0%,
    rgba(2, 0, 36, 1) 100%
  );
  background: -webkit-radial-gradient(
    ellipse at bottom,
    rgba(36, 46, 130, 1) 0%,
    rgba(2, 0, 36, 1) 100%
  );
  background: radial-gradient(
    ellipse at bottom,
    rgba(36, 46, 130, 1) 0%,
    rgba(2, 0, 36, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#242e82",endColorstr="#020024",GradientType=1);
  color: white;
  text-align: center;
  font-family: "Quicksand", sans-serif, Arial, Helvetica, sans-serif;
  padding-top: 70px;
  padding-bottom: 10px;
}

.body h1 {
  padding-top: 20px;
}

.img {
  width: 55%;
  height: 55%;
  border-radius: 50%;
  padding: 3px;
  border: 1px solid black;
  box-shadow: 0px 3px 5px red;
}

.introduction {
  padding: 10px;
  padding-bottom: 0px;
  margin: 20px auto;
  width: 80%;
  background-color: #2a282b;
  border-radius: 10px;
  box-shadow: 0px 3px 5px black;
}

.introduction p {
  padding-top: 10px;
}

@media only screen and (min-width: 326px) {
  .body {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 800px) {
  .body {
    padding-top: 0px;
  }

  .img {
    width: 40%;
    height: 40%;
  }

  .introduction {
    max-width: 50%;
    height: 100%;
    padding: 10px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .introduction {
    margin-top: 40px;
  }

  .img {
    width: 30%;
    height: 25%;
  }
}
